
import { computed, defineComponent, PropType, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import CFormCheckbox from "@/components/form/Checkbox.vue";

import { addMoneyMask } from "@/libs/utils";

export type VPaymentGuideSearch = {
  cd_pagamento?: number;
  cd_estabelecimento?: number;
  dt_execucao_inicial: string;
  dt_execucao_final: string;
};
const VPaymentGuideForm = defineComponent({
  name: "VPaymentGuideForm",
  components: { PDataTable, PColumn, PProgressBar, CFormCheckbox },
  props: {
    selected: { type: Array as PropType<number[]>, default: () => [] },
    search: { type: Object as PropType<VPaymentGuideSearch>, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isMakeForm = route.name?.toString().includes("-make");

    const guides = computed(() => store.state.guide.searchList);

    const loading = reactive({ guide: false, print: false, printAll: false, submit: false });
    const selectedGuides = ref<{ [key: string]: boolean }>({});

    async function getGuides() {
      if (!props.search.cd_estabelecimento) return;

      loading.guide = true;
      await store.dispatch("searchGuides", {
        search: { ...props.search, cd_guia_situacao: 5 },
        isAll: true,
        config: { useCompany: false },
      });
      loading.guide = false;
    }

    function handleClose() {
      router.back();
    }

    function handleSelectGuide() {
      emit("select:guide", { ids: Object.keys(selectedGuides.value).filter((key) => selectedGuides.value[key]) });
    }

    getGuides();
    watch(() => props.search, getGuides);
    watch(
      () => props.selected,
      () => {
        selectedGuides.value = {};
        props.selected?.forEach((_id) => (selectedGuides.value[_id] = true));
      }
    );

    return {
      ...{ guides, loading, selectedGuides, isMakeForm },
      ...{ handleClose, handleSelectGuide },
      ...{ addMoneyMask },
    };
  },
});

export default VPaymentGuideForm;
