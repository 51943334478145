import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { id: "v-payment-guide-form" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { style: {"font-size":"0.85rem"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_progress_bar = _resolveComponent("p-progress-bar")!
  const _component_c_form_checkbox = _resolveComponent("c-form-checkbox")!
  const _component_p_column = _resolveComponent("p-column")!
  const _component_p_data_table = _resolveComponent("p-data-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_p_data_table, {
      value: _ctx.guides,
      stripedRows: ""
    }, {
      empty: _withCtx(() => [
        (_ctx.loading.guide)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Aguarde..."))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, "Nenhuma guia encontrada."))
      ]),
      default: _withCtx(() => [
        (_ctx.loading.guide)
          ? (_openBlock(), _createBlock(_component_p_progress_bar, {
              key: 0,
              mode: "indeterminate"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_p_column, {
          header: "Validar",
          headerStyle: "width: 80px"
        }, {
          body: _withCtx(({ data }) => [
            _createVNode(_component_c_form_checkbox, {
              modelValue: _ctx.selectedGuides[data.cd_guia_eletronica_id],
              "onUpdate:modelValue": ($event: any) => ((_ctx.selectedGuides[data.cd_guia_eletronica_id]) = $event),
              elementID: `guide-checkbox-${data.cd_guia_eletronica_id}`,
              isDisabled: _ctx.isMakeForm,
              onChange: _ctx.handleSelectGuide
            }, null, 8, ["modelValue", "onUpdate:modelValue", "elementID", "isDisabled", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_p_column, {
          field: "cd_guia_eletronica_id",
          header: "Cód. guia",
          headerStyle: "width: 120px"
        }),
        _createVNode(_component_p_column, {
          field: "nm_procedimento",
          header: "Procedimento"
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(data.nm_procedimento), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_p_column, {
          header: "Repasse",
          headerStyle: "width: 140px"
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(_ctx.addMoneyMask(data.valor_repasse)), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}