
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CTabs, { CTabItem } from "@/components/Tabs.vue";
import CModal from "@/components/Modal.vue";
import CFormTextarea from "@/components/form/Textarea.vue";
import CFormInputText from "@/components/form/InputText.vue";
import CFormInputMoney from "@/components/form/InputMoney.vue";
import CFormSelect from "@/components/form/Select.vue";
import VPaymentGuideForm, { VPaymentGuideSearch } from "@/views/payment/GuideForm.vue";

import PCalendar from "primevue/calendar";
import PProgressBar from "primevue/progressbar";

import { PaymentForm } from "@/store/payment/types";
import { formatDate, formatDateEn, formatDateMMAAAA, removeHoursOfDate, isEditFormByRouteName } from "@/libs/utils";

const VPaymentForm = defineComponent({
  name: "VPaymentForm",
  components: {
    ...{ CModal, CTabs, CFormInputText, CFormTextarea, CFormSelect, CFormInputMoney, VPaymentGuideForm },
    ...{ PProgressBar, PCalendar },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const _id = Number(route.params._id);
    const isEditForm = isEditFormByRouteName(route.name);
    const isMakeForm = route.name?.toString().includes("-make");

    useHead({
      title: `${isEditForm ? "Editar faturamento" : isMakeForm ? "Faturar" : "Cadastrar faturamento"} | obmed`,
    });

    const payment = computed(() => store.state.payment.current);
    const banks = computed(() => store.state.bank.all);
    const guides = computed(() => store.state.guide.searchList);
    const establishments = computed(() => store.state.establishment.all);

    const tabs: CTabItem[] = [
      { label: "Pagamento", component: "payment", icon: "fas fa-dollar-sign" },
      { label: "Guias", component: "guide", icon: "fas fa-clipboard-list" },
    ];
    const currentTab = ref(tabs.find((item) => route.query.tab?.toString() === item.component) || tabs[0]);
    const establishmentID = ref<number | undefined>(undefined);

    const form = reactive<PaymentForm>({
      cd_banco: null,
      cd_estabelecimento: null,
      cd_guia_eletronica: [],
      ds_pagamento: "",
      dt_competencia: "",
      dt_final: "",
      dt_inicial: "",
      dt_pagamento: "",
      nr_agenciabancaria: "",
      nr_contabancaria: "",
      nr_descontoinss: "0",
      nr_impostofederal: "0",
      nr_operacaobancaria: "",
      ie_status_pagamento: "A",
    });
    const loading = reactive({ payment: false, submit: false });
    const guideSearch = computed<VPaymentGuideSearch>(() => ({
      cd_pagamento: payment.value?.id,
      dt_execucao_inicial: payment.value?.dt_inicial || removeHoursOfDate(form.dt_inicial) || "",
      dt_execucao_final: payment.value?.dt_final || removeHoursOfDate(form.dt_final) || "",
      cd_estabelecimento: payment.value?.cd_estabelecimento?.id || establishmentID.value,
    }));
    const grossValue = ref("0");
    const invoice = ref("");

    function handleClose() {
      router.back();
    }

    function handleSelectGuide(params: { ids: string[] }) {
      form.cd_guia_eletronica = params.ids.map((_id) => Number(_id));
      grossValue.value = guides.value
        .filter((item) => form.cd_guia_eletronica.includes(item.id_guia_eletronica))
        .reduce((total, item) => total + item.valor_repasse, 0)
        .toString();
    }

    async function onSubmit() {
      if (!isEditForm && !isMakeForm && !establishmentID.value)
        return store.commit("addToast", { summary: 'O campo "Clínica" é obrigatorio', severity: "warn" });

      loading.submit = true;
      const dt_inicial = formatDateEn(form.dt_inicial);
      const dt_final = formatDateEn(form.dt_final);
      const dt_pagamento = formatDateEn(form.dt_pagamento);
      const dt_competencia = form.dt_competencia.toString().includes("/")
        ? form.dt_competencia
        : formatDateMMAAAA(form.dt_competencia);
      const cd_estabelecimento = establishmentID.value || null;

      const response = isEditForm
        ? await store.dispatch("updatePayment", {
            _id,
            form: { ...form, dt_inicial, dt_final, dt_pagamento, dt_competencia },
          })
        : isMakeForm
        ? await store.dispatch("makePayment", {
            _id,
            form: {
              cd_banco: form.cd_banco,
              dt_pagamento,
              ie_status_pagamento: "P",
              nr_nota_fical: invoice.value,
              nr_agenciabancaria: form.nr_agenciabancaria,
              nr_operacaobancaria: form.nr_operacaobancaria,
            },
          })
        : await store.dispatch("createPayment", {
            form: { ...form, dt_inicial, dt_final, dt_pagamento, dt_competencia, cd_estabelecimento },
          });
      loading.submit = false;

      if (response?.status === 200 || response?.status === 201) {
        store.dispatch("getPayments");
        router.replace({ name: "payment-list" });
      }
    }

    async function getPayment() {
      loading.payment = true;
      await store.dispatch("getPayment", { _id });
      loading.payment = false;
    }

    async function handleApplyForm() {
      await getPayment();

      form.cd_banco = payment.value?.cd_banco?.id || null;
      form.cd_guia_eletronica = payment.value?.guiaeletronica_set || [];
      form.ds_pagamento = payment.value?.ds_pagamento || "";
      form.dt_competencia = formatDateMMAAAA(payment.value?.dt_competencia) || "";
      form.dt_final = formatDate(payment.value?.dt_final) || "";
      form.dt_inicial = formatDate(payment.value?.dt_inicial) || "";
      form.dt_pagamento = formatDate(payment.value?.dt_pagamento) || "";
      form.nr_agenciabancaria = payment.value?.nr_agenciabancaria || "";
      form.nr_contabancaria = payment.value?.nr_contabancaria || "";
      form.nr_descontoinss = payment.value?.nr_descontoinss || "0";
      form.nr_impostofederal = payment.value?.nr_impostofederal || "0";
      form.nr_operacaobancaria = payment.value?.nr_operacaobancaria || "";

      grossValue.value = payment.value?.nr_valorbruto || "";
      invoice.value = payment.value?.nr_nota_fical || "";
    }

    if (!banks.value.length) store.dispatch("getAllBanks");
    if (!establishments.value.length) store.dispatch("getAllEstablishment");

    if (isEditForm || isMakeForm) handleApplyForm();
    else {
      store.commit("setPayment", null);
      store.commit("setSearchGuides", []);
    }

    return {
      ...{ form, payment, banks, establishments, tabs, grossValue, establishmentID, invoice, guideSearch },
      ...{ loading, isEditForm, isMakeForm, currentTab },
      ...{ handleClose, onSubmit, handleSelectGuide },
    };
  },
});

export default VPaymentForm;
