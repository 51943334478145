import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "c-tabs tabs is-centered is-boxed" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "icon is-small" }
const _hoisted_4 = { class: "tab-label" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "icon is-small" }
const _hoisted_7 = { class: "tab-label" }
const _hoisted_8 = { class: "icon is-small" }
const _hoisted_9 = { class: "tab-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass({ 'is-active': tab.label === _ctx.current.label }),
          onClick: ($event: any) => (_ctx.$emit('update:current', tab))
        }, [
          (tab.to)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { ...tab.to, query: { ..._ctx.$route.query, ...(tab.to?.query || {}) } }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, [
                    _createElementVNode("i", {
                      class: _normalizeClass(tab.icon),
                      "aria-hidden": "true"
                    }, null, 2)
                  ]),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(tab.label), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (tab.href)
              ? (_openBlock(), _createElementBlock("a", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, [
                    _createElementVNode("i", {
                      class: _normalizeClass(tab.icon),
                      "aria-hidden": "true"
                    }, null, 2)
                  ]),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(tab.label), 1)
                ]))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 2,
                  to: { query: { ..._ctx.$route.query, tab: tab.component } },
                  replace: ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_8, [
                      _createElementVNode("i", {
                        class: _normalizeClass(tab.icon),
                        "aria-hidden": "true"
                      }, null, 2)
                    ]),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(tab.label), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
        ], 10, _hoisted_2))
      }), 128))
    ])
  ]))
}